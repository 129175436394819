define("ember-yeti-table/components/yeti-table/pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HudwlTI7",
    "block": "{\"symbols\":[\"pageSize\",\"@theme\",\"@paginationActions\",\"@paginationData\",\"@disabled\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,2,[\"pagination\",\"controls\"]]],[17,6],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"showInfo\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[32,2,[\"pagination\",\"info\"]]],[12],[2,\"\\n      Showing \"],[1,[32,4,[\"pageStart\"]]],[2,\" to \"],[1,[32,4,[\"pageEnd\"]]],[2,\" of \"],[1,[32,4,[\"totalRows\"]]],[2,\" entries\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[32,0,[\"showPageSizeSelector\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[32,2,[\"pagination\",\"pageSize\"]]],[12],[2,\"\\n      Rows per page:\\n      \"],[11,\"select\"],[16,\"disabled\",[32,5]],[4,[38,0],[\"change\",[32,0,[\"changePageSize\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"pageSizes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"option\"],[15,2,[32,1]],[15,\"selected\",[30,[36,1],[[32,4,[\"pageSize\"]],[32,1]],null]],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[32,0,[\"showButtons\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[32,2,[\"pagination\",\"previous\"]]],[16,\"disabled\",[32,0,[\"shouldDisablePrevious\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[32,3,[\"previousPage\"]]],null],[12],[2,\"\\n      Previous\\n    \"],[13],[2,\"\\n\\n    \"],[11,\"button\"],[16,0,[32,2,[\"pagination\",\"next\"]]],[16,\"disabled\",[32,0,[\"shouldDisableNext\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[32,3,[\"nextPage\"]]],null],[12],[2,\"\\n      Next\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"yeti-table-eq\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "ember-yeti-table/components/yeti-table/pagination/template.hbs"
    }
  });

  _exports.default = _default;
});