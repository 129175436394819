define("ember-animated/components/animated-beacon", ["exports", "ember-animated/-private/ember-scheduler", "ember-animated", "ember-animated/-private/ember-internals", "ember-animated/-private/sprite", "ember-animated/templates/components/animated-beacon"], function (_exports, _emberScheduler, _emberAnimated, _emberInternals, _sprite, _animatedBeacon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
    A component that marks a region of the page that
    can serve as a source or destination for sprites to animate to and from.
  
    ```hbs
    {{#animated-beacon name="one"}}
      <button {{action "launch"}}>Launch</button>
    {{/animated-beacon}}
  
    {{#animated-if showThing use=transition}}
      <div class="message" {{action "dismiss"}}>
        Hello
      </div>
    {{/animated-if}}
    ```
  
    ```js
    import Component from '@ember/component';
    import move from 'ember-animated/motions/move';
    import scale from 'ember-animated/motions/scale';
  
    export default Component.extend({
      showThing: false,
  
      transition: function *({ insertedSprites, keptSprites, removedSprites, beacons }) {
        for (let sprite of insertedSprites) {
          sprite.startAtSprite(beacons.one);
          move(sprite);
          scale(sprite);
        }
  
        for (let sprite of keptSprites) {
          move(sprite);
        }
  
        for (let sprite of removedSprites) {
          sprite.endAtSprite(beacons.one);
          move(sprite);
          scale(sprite);
        }
      });
    },
  
      actions: {
        launch() {
          this.set('showThing', true);
        },
        dismiss() {
          this.set('showThing', false);
        }
      }
    });
    ```
    @class animated-beacon
    @public
  */
  let AnimatedBeacon = (_dec = Ember.inject.service('-ea-motion'), _dec2 = (0, _emberScheduler.task)(function* () {
    let element = this._firstChildElement();

    if (!element) {
      return;
    }

    let offsetParent = _sprite.default.offsetParentStartingAt(element);

    let sprite = _sprite.default.positionedStartingAt(element, offsetParent);

    yield (0, _emberAnimated.afterRender)();
    yield (0, _emberAnimated.microwait)();
    yield* this.get('motionService').staticMeasurement(() => {
      offsetParent.measureFinalBounds();
      sprite.measureFinalBounds();
    });
    yield this.get('motionService').get('addBeacon').perform(this.name, sprite);
  }), (_class = class AnimatedBeacon extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "name", void 0);

      _defineProperty(this, "layout", _animatedBeacon.default);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "_inserted", false);

      _initializerDefineProperty(this, "motionService", _descriptor, this);

      _initializerDefineProperty(this, "participate", _descriptor2, this);
    }

    didInsertElement() {
      super.didInsertElement();
      this._inserted = true;
      this.animationStarting = this.animationStarting.bind(this);
      this.get('motionService').observeAnimations(this.animationStarting);
    }

    willDestroyElement() {
      super.willDestroyElement();
      this.get('motionService').unobserveAnimations(this.animationStarting);
    }

    animationStarting() {
      this.get('participate').perform();
    }

    _firstChildElement() {
      if (this._inserted) {
        let {
          firstNode,
          lastNode
        } = (0, _emberInternals.componentNodes)(this);
        let node = firstNode;

        while (node) {
          if (node.nodeType === Node.ELEMENT_NODE) {
            return node;
          }

          if (node === lastNode) {
            break;
          }

          node = node.nextSibling;
        }
      }

      return undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "motionService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "participate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AnimatedBeacon;
});