define("ember-power-select/components/power-select/before-options", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @searchEnabled}}
    <div class="ember-power-select-search">
      <input type="search" autocomplete="off"
        autocorrect="off" autocapitalize="off"
        spellcheck={{false}} role="combobox"
        class="ember-power-select-search-input"
        value={{@select.searchText}}
        aria-controls={{@listboxId}}
        placeholder={{@searchPlaceholder}}
        {{on "input" @onInput}}
        {{on "focus" @onFocus}}
        {{on "blur" @onBlur}}
        {{on "keydown" this.handleKeydown}}
        {{did-insert this.focusInput}}
        {{will-destroy this.clearSearch}}>
    </div>
  {{/if}}
  */
  {
    "id": "JCvYnh3q",
    "block": "{\"symbols\":[\"@select\",\"@listboxId\",\"@searchPlaceholder\",\"@onInput\",\"@onFocus\",\"@onBlur\",\"@searchEnabled\"],\"statements\":[[6,[37,3],[[32,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ember-power-select-search\"],[12],[2,\"\\n    \"],[11,\"input\"],[24,\"autocomplete\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"],[16,\"spellcheck\",false],[24,\"role\",\"combobox\"],[24,0,\"ember-power-select-search-input\"],[16,2,[32,1,[\"searchText\"]]],[16,\"aria-controls\",[32,2]],[16,\"placeholder\",[32,3]],[24,4,\"search\"],[4,[38,0],[\"input\",[32,4]],null],[4,[38,0],[\"focus\",[32,5]],null],[4,[38,0],[\"blur\",[32,6]],null],[4,[38,0],[\"keydown\",[32,0,[\"handleKeydown\"]]],null],[4,[38,1],[[32,0,[\"focusInput\"]]],null],[4,[38,2],[[32,0,[\"clearSearch\"]]],null],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"did-insert\",\"will-destroy\",\"if\"]}",
    "meta": {
      "moduleName": "ember-power-select/components/power-select/before-options.hbs"
    }
  });

  let BeforeOptions = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class BeforeOptions extends _component.default {
    clearSearch() {
      Ember.run.scheduleOnce('actions', this.args.select.actions, 'search', '');
    }

    handleKeydown(e) {
      if (this.args.onKeydown(e) === false) {
        return false;
      }

      if (e.keyCode === 13) {
        this.args.select.actions.close(e);
      }
    }

    focusInput(el) {
      Ember.run.later(() => {
        if (this.args.autofocus !== false) {
          el.focus();
        }
      }, 0);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "clearSearch", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clearSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeydown", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "focusInput"), _class.prototype)), _class));
  _exports.default = BeforeOptions;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BeforeOptions);
});