define("ember-yeti-table/components/yeti-table/thead/row/component", ["exports", "@ember-decorators/component", "ember-yeti-table/components/yeti-table/thead/row/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
    Renders a `<tr>` element and yields the column and cell component.
    ```hbs
    <table.thead as |head|>
      <head.row as |row|>
        <row.column @prop="firstName" as |column|>
          First name
          {{if column.isAscSorted "(sorted asc)"}}
          {{if column.isDescSorted "(sorted desc)"}}
        </head.column>
      </head.row>
    </table.thead>
    ```
  
    @yield {Component} column
    @yield {Component} cell
  */
  let THeadRow = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class THeadRow extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "theme", void 0);

      _defineProperty(this, "parent", void 0);

      _defineProperty(this, "columns", void 0);

      _defineProperty(this, "sortable", true);

      _defineProperty(this, "sort", null);

      _defineProperty(this, "sortSequence", void 0);

      _defineProperty(this, "onColumnClick", void 0);

      _defineProperty(this, "cells", Ember.A());
    }

    registerCell(cell) {
      let columns = this.get('columns');
      let prop = cell.get('prop');

      if (prop) {
        let column = columns.findBy('prop', prop);
        cell.set('column', column);
      } else {
        let index = this.get('cells.length');
        let column = columns[index];
        cell.set('column', column);
      }

      this.get('cells').addObject(cell);
    }

    unregisterCell(cell) {
      this.get('cells').removeObject(cell);
    }

  }) || _class) || _class);
  var _default = THeadRow;
  _exports.default = _default;
});