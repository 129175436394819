define("ember-yeti-table/components/yeti-table/tfoot/row/component", ["exports", "@ember-decorators/component", "ember-yeti-table/components/yeti-table/tfoot/row/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
    Renders a `<tr>` element and yields cell component.
    ```hbs
    <table.tfoot as |foot|>
      <foot.row as |row|>
        <row.cell>
          Footer content
        </row.cell>
      </foot.row>
    </table.tfoot>
    ```
  
    @yield {object} row
    @yield {Component} row.cell
  */
  let TFootRow = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class TFootRow extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "theme", void 0);

      _defineProperty(this, "parent", void 0);

      _defineProperty(this, "columns", void 0);

      _defineProperty(this, "cells", Ember.A());
    }

    registerCell(cell) {
      let columns = this.get('columns');
      let prop = cell.get('prop');

      if (prop) {
        let column = columns.findBy('prop', prop);
        cell.set('column', column);
      } else {
        let index = this.get('cells.length');
        let column = columns[index];
        cell.set('column', column);
      }

      this.get('cells').addObject(cell);
    }

    unregisterCell(cell) {
      this.get('cells').removeObject(cell);
    }

  }) || _class) || _class);
  var _default = TFootRow;
  _exports.default = _default;
});