define("ember-animated/components/ea-list-element", ["exports", "ember-animated/-private/ember-internals"], function (_exports, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /*
     This component has one job: tracking which DOM elements correspond
     with which list elements.
  */
  class _default extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "isEmberAnimatedListElement", true);

      _defineProperty(this, "child", void 0);

      _defineProperty(this, "elementToChild", void 0);
    }

    didRender() {
      let mapping = this.get('elementToChild');
      let child = this.get('child');

      this._forEachElement(elt => {
        mapping.set(elt, child);
      });
    }

    _forEachElement(fn) {
      let {
        firstNode,
        lastNode
      } = (0, _emberInternals.componentNodes)(this);
      let node = firstNode;

      while (node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
          fn(node);
        } else if (!/^\s*$/.test(node.textContent)) {
          (false && Ember.warn('Found bare text content inside an animator', false, {
            id: 'ember-animated-bare-text'
          }));
        }

        if (node === lastNode) {
          break;
        }

        node = node.nextSibling;
      }
    }

  }

  _exports.default = _default;
});