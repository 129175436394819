define("ember-changeset-validations/validators/presence", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-validators/presence"], function (_exports, _validationErrors, _presence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatePresence;

  function validatePresence(options) {
    let targets;

    if (typeof options === 'boolean') {
      options = {
        presence: options
      };
    } else if (options && options.on !== undefined) {
      if (typeof options.on === 'string') {
        targets = [options.on];
      } else if (Array.isArray(options.on)) {
        targets = options.on;
      }

      delete options.on;
    }

    return (key, value, _oldValue, changes, content) => {
      if (targets && !targets.some(target => {
        const change = Ember.get(changes, target);
        return change || change === undefined && Ember.get(content, target);
      })) {
        return true;
      }

      let result = (0, _presence.default)(value, options, null, key);

      if (typeof result === 'boolean' || typeof result === 'string') {
        return result;
      } else {
        // We flipped the meaning behind `present` and `blank` so switch the two
        if (result.type === 'present') {
          result.type = 'blank';
        } else if (result.type === 'blank') {
          result.type = 'present';
        }

        return (0, _validationErrors.default)(key, result);
      }
    };
  }
});