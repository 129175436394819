define("ember-animated-tools/templates/components/animated-tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n8fiHAXn",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[6,[37,4],[[32,0,[\"isHidden\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,0,[31,[\"animated-tools \",[30,[36,0],[[32,0,[\"isOpen\"]],\"is-open\"],null]]]],[17,1],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"animated-tools-launcher\"],[4,[38,1],[[32,0],\"toggle\"],null],[12],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n      ⏱\"],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"animated-tools-panel\"],[12],[2,\"\\n      \"],[1,[34,3]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"motion-indicator\",\"time-control\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-animated-tools/templates/components/animated-tools.hbs"
    }
  });

  _exports.default = _default;
});