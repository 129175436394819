define("ember-animated/components/animated-container", ["exports", "ember-animated/motions/resize", "ember-animated/-private/ember-scheduler", "ember-animated/-private/sprite", "ember-animated", "ember-animated/-private/ember-internals", "ember-animated/templates/components/animated-container"], function (_exports, _resize, _emberScheduler, _sprite, _emberAnimated, _emberInternals, _animatedContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   Provides a boundary between animator components and the surrounding document
   which smoothly resizes as animators change. Use animated-container whenever you
   need to "hold a place for" some animated content while that content is animating.
    ```hbs
    <button {{action toggleThing}}>Toggle</button>
    <AnimatedContainer>
      {{#animated-if showThing use=transition }}
          <div class="message" {{action "toggleThing"}}>
              Hello!
          </div>
      {{/animated-if}}
    </AnimatedContainer>
    <p>
      This is outside of the container.
    </p>
    ```
    ```js
    import Component from '@ember/component';
    import move from 'ember-animated/motions/move';
    import {easeOut, easeIn } from 'ember-animated/easings/cosine';
  
    export default Component.extend({
      showThing: false,
  
      toggleThing() {
        this.set('showThing', !this.get('showThing'));
      },
  
      transition: function * ({ insertedSprites, keptSprites, removedSprites }) {
        for (let sprite of insertedSprites) {
          sprite.startAtPixel({ x: window.innerWidth });
          move(sprite, { easing: easeOut });
        }
  
        for (let sprite of keptSprites) {
          move(sprite);
        }
  
        for (let sprite of removedSprites) {
          sprite.endAtPixel({ x: window.innerWidth });
          move(sprite, { easing: easeIn });
        }
      },
    });
    ```
    @class animated-container
    @public
  */
  let AnimatedContainerComponent = (_dec = Ember.inject.service('-ea-motion'), _dec2 = Ember.computed.alias('animated.isRunning'), _dec3 = Ember._action, _dec4 = (0, _emberScheduler.task)(function* (duration, animationTask) {
    this._startingUp = true;
    let service = this.get('motionService');
    let sprite;
    let useMotion;

    let element = this._ownElement();

    if (element) {
      sprite = _sprite.default.sizedStartingAt(element);
      this.sprite = sprite;
      sprite.lock();
      useMotion = true;
    } else {
      useMotion = this.get('onInitialRender');
    }

    try {
      yield (0, _emberAnimated.afterRender)();
      yield (0, _emberAnimated.microwait)();
    } finally {
      this._startingUp = false;
    }

    yield* service.staticMeasurement(() => {
      if (!sprite) {
        // ownElement is non-null here because we waited for render above, and
        // our own template definitely contains an Element
        sprite = _sprite.default.sizedEndingAt(this._ownElement());
        this.sprite = sprite;
      } else {
        sprite.measureFinalBounds();
      }
    });

    if (useMotion) {
      yield* new this.motion(this.sprite, {
        duration
      })._run();
    }

    yield animationTask;
    this.sprite.unlock();
    this.sprite = null;
  }).restartable(), (_class = class AnimatedContainerComponent extends Ember.Component {
    /**
     * Use a custom tag for the container. Defaults to div.
      @argument tag
      @type String
    */

    /**
     * Whether to animate the initial render. You will probably also need to set
     * initialInsertion=true on a child component of animated-container.
     * Defaults to false.
      @argument onInitialRender
      @type Boolean
    */

    /**
     * Use a custom tag for the container. Defaults to div.
      @argument motion
      @type String
    */
    constructor(properties) {
      super(properties);

      _defineProperty(this, "layout", _animatedContainer.default);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "motionService", _descriptor, this);

      _defineProperty(this, "tag", 'div');

      _defineProperty(this, "onInitialRender", false);

      _defineProperty(this, "motion", _resize.Resize);

      _defineProperty(this, "_inserted", false);

      _defineProperty(this, "_startingUp", false);

      _defineProperty(this, "sprite", null);

      _initializerDefineProperty(this, "isAnimating", _descriptor2, this);

      _initializerDefineProperty(this, "animate", _descriptor3, this);

      this.get('motionService').register(this).observeDescendantAnimations(this, this.maybeAnimate); // TODO: shouldn't need this cast;
    }

    didInsertElement() {
      this._inserted = true;
    }

    _ownElement() {
      if (!this._inserted) {
        return undefined;
      }

      let {
        firstNode,
        lastNode
      } = (0, _emberInternals.componentNodes)(this);
      let node = firstNode;

      while (node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
          return node;
        }

        if (node === lastNode) {
          break;
        }

        node = node.nextSibling;
      }

      return undefined;
    }

    willDestroyElement() {
      this.get('motionService').unregister(this).unobserveDescendantAnimations(this, this.maybeAnimate); // TODO: shouldn't need this cast
    }

    maybeAnimate(_ref) {
      let {
        duration,
        task
      } = _ref;

      if (!this._startingUp) {
        this.get('animate').perform(duration, task);
      }
    }

    beginStaticMeasurement() {
      if (this.sprite) {
        this.sprite.unlock();
      }
    }

    endStaticMeasurement() {
      if (this.sprite) {
        this.sprite.lock();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "motionService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isAnimating", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "maybeAnimate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "maybeAnimate"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "animate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AnimatedContainerComponent;
});