define("@ember/legacy-built-in-components/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TextField = _exports.TextArea = _exports.LinkComponent = _exports.Checkbox = void 0;
  let Checkbox;
  _exports.Checkbox = Checkbox;
  {
    _exports.Checkbox = Checkbox = Ember.Checkbox;
  }
  let LinkComponent;
  _exports.LinkComponent = LinkComponent;
  {
    _exports.LinkComponent = LinkComponent = Ember.LinkComponent;
  }
  let TextArea;
  _exports.TextArea = TextArea;
  {
    _exports.TextArea = TextArea = Ember.TextArea;
  }
  let TextField;
  _exports.TextField = TextField;
  {
    _exports.TextField = TextField = Ember.TextField;
  }
});