define('ember-js-pdf/components/js-pdf', ['exports', 'ember-js-pdf/templates/components/js-pdf', 'ember-js-pdf/commands'], function (exports, _jsPdf, _commands) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const keys = Object.keys;
  const isArray = Array.isArray;


  const JsPdfComponent = Ember.Component.extend({
    layout: _jsPdf.default,
    classNames: ['ember-js-pdf'],

    /**
     * Saved filename of generated PDF
     * @type {String}
     */
    filename: Ember.computed({
      get() {
        return `${Ember.get(this, 'elementId') || 'no-name'}.pdf`;
      },

      set(key, value = '') {

        // Enforce string as filename
        value = `${value}`;

        // Enforce `.pdf` file type
        if (value.search(/\.pdf$/i) === -1) {
          value = `${value}.pdf`;
        }

        return value;
      }
    }),

    /**
     * Is PDF document rendered
     * @type {Boolean}
     */
    showPdf: true,

    /**
     * PDF frame width
     * @type {String}
     */
    width: '100%',

    /**
     * PDF frame height
     * @type {String}
     */
    height: '500px',

    /**
     * PDF document orientation
     * @type {String}
     */
    orientation: 'p',

    /**
     * Measurement unit used
     * @type {String}
     */
    unit: 'mm',

    /**
     * PDF page formats
     * @type {String}
     */
    format: 'a4',

    /**
     * Whether to compress output pdf
     * @type {Boolean}
     */
    compressPdf: false,

    /**
     * Current object generated from new jsPDF()
     * @type {Object}
     */
    content: Ember.computed('steps.[]', 'orientation', 'unit', 'format', 'compressPdf', {
      get() {
        var _Ember$getProperties = Ember.getProperties(this, 'orientation', 'unit', 'format', 'compressPdf');

        const orientation = _Ember$getProperties.orientation,
              unit = _Ember$getProperties.unit,
              format = _Ember$getProperties.format,
              compressPdf = _Ember$getProperties.compressPdf;
        (false && !(typeof orientation === 'string' && orientation.length) && Ember.assert('{{js-pdf}} requires a valid PDF `orientation`', typeof orientation === 'string' && orientation.length));
        (false && !(typeof unit === 'string' && unit.length) && Ember.assert('{{js-pdf}} requires a measurment as `unit`', typeof unit === 'string' && unit.length));
        (false && !(typeof format === 'string' && format.length) && Ember.assert('{{js-pdf}} requires a valid page `format`', typeof format === 'string' && format.length));


        return new jsPDF(orientation, unit, format, compressPdf);
      },

      set(key, value) {
        return value;
      }
    }),

    /**
     * Base64 encoding of PDF document
     * @type {String}
     */
    src: Ember.computed('steps.[]', 'orientation', 'unit', 'format', 'compressPdf', function () {
      const jsPdf = Ember.get(this, 'content');
      const steps = Ember.get(this, 'steps');

      (false && !(isArray(steps)) && Ember.assert('{{js-pdf}} requires an array of rendering steps', isArray(steps)));

      addStepsToJsPdf(jsPdf, steps);

      return jsPdf.output('dataurlstring');
    }),

    /**
     * Trigger garbage collection of jsPDF instance
     */
    willDestroyElement() {
      this._super(...arguments);
      Ember.set(this, 'content', null);
    },

    actions: {
      onSave() {
        const filename = Ember.get(this, 'filename');
        const action = Ember.get(this, 'onSave');
        const jsPdfInstance = Ember.get(this, 'content');
        const src = Ember.get(this, 'src'); // ensure src computes

        if (action) {
          action(filename, src);
        }

        if (jsPdfInstance) {
          jsPdfInstance.save(filename);
        }
      }
    }
  });

  exports.default = JsPdfComponent.reopenClass({
    positionalParams: ['steps']
  });


  /**
   * Apply PDF rendering steps to a jsPDF Object
   * @param {Object} pdf        pdf generated from new jsPDF()
   * @param {Array}  steps      List of steps to render PDF
   *                            ie: [{text: [35, 25, 'pdf text'] }]
   */
  function addStepsToJsPdf(pdf, steps = []) {
    for (let i = 0; i < steps.length; i++) {
      keys(steps[i]).forEach(command => {
        (false && !(_commands.default.indexOf(command) > -1) && Ember.assert(`{{js-pdf}} steps is given valid command: ${command}`, _commands.default.indexOf(command) > -1));


        let args = steps[i][command];
        if (!isArray(args)) args = [args];

        pdf[command](...args);
      });
    }
  }
});