define("ember-can/computed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ability = ability;

  function ability(abilityName, resourceName) {
    (false && !(false) && Ember.deprecate('Using ability() computed property is deprecated. Use getters and Can service directly.', false, {
      for: 'ember-can',
      since: {
        enabled: '4.0.0'
      },
      id: 'ember-can.deprecate-ability-computed',
      until: '5.0.0'
    }));
    resourceName = resourceName || abilityName;
    return Ember.computed(resourceName, function () {
      let canService = Ember.getOwner(this).lookup('service:abilities');
      return canService.abilityFor(abilityName, Ember.get(this, resourceName));
    }).readOnly();
  }
});