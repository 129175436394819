define("ember-handle-keys-helper/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IE_KEYS_FIX_MAP = void 0;

  /**
    Fixes non-standard key identifiers for IE and Edge
    https://caniuse.com/#feat=keyboardevent-key
  */
  const IE_KEYS_FIX_MAP = {
    Win: 'Meta',
    Scroll: 'ScrollLock',
    Spacebar: ' ',
    Down: 'ArrowDown',
    Left: 'ArrowLeft',
    Right: 'ArrowRight',
    Up: 'ArrowUp',
    Del: 'Delete',
    Apps: 'ContextMenu',
    Esc: 'Escape',
    Multiply: '*',
    Add: '+',
    Subtract: '-',
    Decimal: '.',
    Divide: '/'
  };
  _exports.IE_KEYS_FIX_MAP = IE_KEYS_FIX_MAP;
});