define("ember-yeti-table/components/yeti-table/thead/row/column/component", ["exports", "@ember-decorators/component", "ember-yeti-table/-private/utils/did-change-attrs-component", "ember-yeti-table/components/yeti-table/thead/row/column/template"], function (_exports, _component, _didChangeAttrsComponent, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
    An important component yielded from the header or head.row component that is used to define
    a column of the table.
  
    ```hbs
    <table.header as |header|>
      <header.column @prop="firstName" as |column|>
        First name
        {{if column.isAscSorted "(sorted asc)"}}
        {{if column.isDescSorted "(sorted desc)"}}
      </header.column>
    </table.header>
    ```
  
    ```hbs
    <table.thead as |head|>
      <head.row as |row|>
        <row.column @prop="firstName" as |column|>
          First name
          {{if column.isAscSorted "(sorted asc)"}}
          {{if column.isDescSorted "(sorted desc)"}}
        </row.column>
      </head.row>
    </table.thead>
    ```
  
    @yield {object} column
    @yield {boolean} column.isSorted - `true` if column is sorted ascending or descending
    @yield {boolean} column.isAscSorted - `true` if column is sorted ascending
    @yield {boolean} column.isDescSorted - `true` if column is sorted descending
  */
  let Column = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed.equal('sort', 'asc'), _dec4 = Ember.computed.equal('sort', 'desc'), _dec5 = Ember.computed.or('isAscSorted', 'isDescSorted'), _dec6 = Ember.computed('sortSequence'), _dec7 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class Column extends _didChangeAttrsComponent.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "theme", void 0);

      _defineProperty(this, "parent", void 0);

      _defineProperty(this, "prop", void 0);

      _defineProperty(this, "visible", true);

      _defineProperty(this, "sortable", true);

      _defineProperty(this, "sort", null);

      _defineProperty(this, "sortSequence", void 0);

      _defineProperty(this, "filterable", true);

      _defineProperty(this, "filter", void 0);

      _defineProperty(this, "filterFunction", void 0);

      _defineProperty(this, "filterUsing", void 0);

      _defineProperty(this, "columnClass", void 0);

      _defineProperty(this, "name", void 0);

      _defineProperty(this, "onClick", void 0);

      _initializerDefineProperty(this, "isAscSorted", _descriptor, this);

      _initializerDefineProperty(this, "isDescSorted", _descriptor2, this);

      _initializerDefineProperty(this, "isSorted", _descriptor3, this);

      _defineProperty(this, "noop", () => {});
    }

    get normalizedSortSequence() {
      let sortSequence = this.get('sortSequence');
      (false && !(Ember.isArray(sortSequence) || typeof sortSequence === 'string') && Ember.assert('@sortSequence must be either a comma-separated string or an array. Got `${sortSequence}.`', Ember.isArray(sortSequence) || typeof sortSequence === 'string'));

      if (Ember.isArray(sortSequence)) {
        return sortSequence;
      } else if (typeof sortSequence === 'string') {
        return sortSequence.split(',').map(s => s.trim());
      } else {
        return [];
      }
    }

    init() {
      super.init(...arguments);
      this.didChangeAttrsConfig = {
        attrs: ['filter', 'filterUsing', 'sort']
      };

      if (this.get('parent')) {
        this.get('parent').registerColumn(this);
      }
    }

    didChangeAttrs() {
      this.get('parent').runLoadData();
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);

      if (this.get('parent')) {
        this.get('parent').unregisterColumn(this);
      }
    }

    updateName(element) {
      if (!this.name) {
        Ember.set(this, 'name', element.textContent.trim());
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isAscSorted", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isDescSorted", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isSorted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "normalizedSortSequence", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "normalizedSortSequence"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateName", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "updateName"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = Column;
  _exports.default = _default;
});