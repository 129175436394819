define("ember-animated-tools/helpers/rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.rounded = rounded;

  function rounded(_ref) {
    let [value] = _ref;

    if (value != null) {
      return Math.round(value);
    }
  }

  var _default = Ember.Helper.helper(rounded);

  _exports.default = _default;
});